import {
	CardsThree,
	CaretDoubleLeft,
	ChartPieSlice,
	ClockCounterClockwise,
	CurrencyInr,
	Database,
	Gear,
	House,
	ListDashes,
	Megaphone,
	SignOut,
	Stack,
	UserSquare
} from "@phosphor-icons/react";
import { Layout, Menu, MenuProps, Popover } from "antd";
import clsx from "clsx";
import KeyboardButton from "components/Commons/KeyboardButtons";
import NucleusUpdater from "components/Login/NucleusUpdater";
import { isElectron } from "helpers";
import { USER_ROLES_MAP, useAuthContext } from "pages/Auth/Context";
import { CSSProperties, useEffect, useLayoutEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import sentry from "utils/sentry";
import Search from "./Search";
import "./styles.scss";

type MenuItem = Required<MenuProps>["items"][number] & {
	href?: string;
	showForRole?: TUserRoles[];
	key: string;
	children?: MenuItem[];
	selectedKeys: string[];
};

const _menu: MenuItem[] = [
	{
		key: "/",
		label: "Home",
		href: "/",
		icon: <House size={16} />,
		selectedKeys: ["/"]
	},
	{
		key: "/orders",
		label: "Orders",
		href: "/orders/list",
		icon: <Stack size={16} />,
		selectedKeys: ["/orders"],
		showForRole: ["ADMIN", "FINANCE", "KAM", "MANAGER", "VISA_EXPERT", "VISA_EXPERT_LEAD", "VISA_OPS"]
	},
	{
		key: "/applications",
		label: "Applications",
		href: "/applications/list",
		icon: <CardsThree size={16} />,
		selectedKeys: ["/applications"],
		showForRole: ["ADMIN", "FINANCE", "KAM", "MANAGER", "VISA_EXPERT", "VISA_EXPERT_LEAD", "VISA_OPS"]
	},

	{
		key: "/logistic/orders",
		label: "Orders",
		href: "/logistic/orders",
		icon: <CardsThree size={16} />,
		selectedKeys: ["/logistic/orders"],
		showForRole: ["ADMIN", "MANAGER", "LOGISTICS_HEAD"]
	},
	{
		key: "/logistic/tasks",
		label: "Tasks",
		href: "/logistic/tasks",
		icon: <ListDashes size={16} />,
		selectedKeys: ["/logistic/tasks"],
		showForRole: ["ADMIN", "MANAGER", "LOGISTICS_HEAD", "LOGISTICS_PARTNER"]
	},
	{
		key: "/data-studio",
		label: "Data Studio",
		icon: <Database size={16} />,
		showForRole: ["ADMIN", "DATA_ARCHITECT"],
		selectedKeys: ["/data-studio"],
		children: [
			{
				key: "/data-studio/countries",
				label: "Countries",
				href: "/data-studio/countries",
				selectedKeys: ["/data-studio/countries"]
			},
			{
				key: "/data-studio/visa-types",
				label: "Visa Types",
				href: "/data-studio/visa-types",
				selectedKeys: ["/data-studio/visa-types"]
			},
			{
				key: "/data-studio/verdict-qc-checklist",
				label: "Verdict QC Checklist",
				href: "/data-studio/verdict-qc-checklist",
				selectedKeys: ["/data-studio/verdict-qc-checklist"]
			},
			{
				key: "/data-studio/visa-questions",
				label: "Visa Questions",
				href: "/data-studio/visa-questions",
				selectedKeys: ["/data-studio/visa-questions"]
			},
			{
				key: "/data-studio/documents-requirements",
				label: "Docs Requirements",
				href: "/data-studio/documents-requirements",
				selectedKeys: ["/data-studio/documents-requirements"]
			},
			{
				key: "/data-studio/visa-information",
				label: "Visa Information",
				href: "/data-studio/visa-information",
				selectedKeys: ["/data-studio/visa-information"]
			},
			{
				key: "/data-studio/master-questions",
				label: "Master Questions",
				href: "/data-studio/master-questions",
				selectedKeys: ["/data-studio/master-questions"]
			}
		]
	},
	{
		key: "/pricing",
		label: "Pricing",
		href: "/pricing",
		icon: <CurrencyInr size={16} />,
		showForRole: ["ADMIN", "DATA_ARCHITECT"],
		selectedKeys: ["/pricing"]
	},
	{
		key: "/visa-prices-changelog",
		label: "Visa Prices Changelog",
		href: "/visa-prices-changelog",
		icon: <ClockCounterClockwise size={16} />,
		showForRole: ["ADMIN", "MANAGER", "KAM", "DATA_ARCHITECT", "CX", "VISA_EXPERT", "VISA_EXPERT_LEAD", "VISA_OPS"],
		selectedKeys: ["/visa-prices-changelog"]
	},
	{
		key: "/kyc-requests",
		label: "KYC Requests",
		href: "/kyc-requests",
		icon: <UserSquare size={16} />,
		showForRole: ["ADMIN", "MANAGER", "KAM", "CX"],
		selectedKeys: ["/kyc-requests"]
	},
	{
		key: "/segments",
		label: "Segments",
		href: "/segments",
		icon: <Stack size={16} />,
		selectedKeys: ["/segments"],
		showForRole: ["ADMIN", "CX"]
	},
	{
		key: "/reports",
		label: "Reports",
		href: "/reports",
		icon: <ChartPieSlice size={16} />,
		selectedKeys: ["/reports"],
		showForRole: ["ADMIN", "MANAGER", "KAM", "VISA_EXPERT", "VISA_EXPERT_LEAD", "VISA_OPS"]
	}
];

function Sidebar() {
	const { user, updateUser, userRoleMenuItem, isUserInRoles } = useAuthContext();
	const location = useLocation();
	const navigate = useNavigate();
	const [isMinimized, setIsSidebarMinimized] = useLocalStorage("isSidebarMinimized", false);

	const toggleSidebar = () => {
		setIsSidebarMinimized(!isMinimized);
	};

	const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

	const logout = async () => {
		Mixpanel.track(MIXPANEL_EVENTS.LOGOUT_SUCCESS, { loggedOutUser: user?.email });
		updateUser(null);
		localStorage.removeItem("nucleus-jwt-token");
		Mixpanel.reset();
		sentry.setUser(null);
		navigate("/auth/sign-in");
	};

	const handleOnViewChangelog = () => navigate("/changelog");

	useLayoutEffect(() => {
		const path = location.pathname; // Get the current path

		// Function to find the best matching menu item for the given path
		const findBestMatchMenuItem = (path: string, menuItems: MenuItem[]): MenuItem | undefined => {
			for (const item of menuItems) {
				if (item.children) {
					const childMatch = findBestMatchMenuItem(path, item.children);
					if (childMatch) {
						// If a child is matched, return the selectedKeys from both the parent and the child
						return { ...item, selectedKeys: [...item.selectedKeys, ...childMatch.selectedKeys] };
					}
				}

				if (path === item.key || path.startsWith(item.key + "/")) {
					return item;
				}
			}
			return undefined;
		};

		const menuItem = findBestMatchMenuItem(path, _menu);

		if (menuItem) {
			setSelectedKeys(menuItem.selectedKeys);
		} else {
			// Fallback to home if no other matches are found
			setSelectedKeys(["/"]);
		}
	}, [location.pathname]);

	const initialOfName = user?.first_name?.charAt(0) ?? "" + user?.last_name?.charAt(0) ?? "";

	const [opacity, setOpacity] = useState(0);
	useEffect(() => {
		setTimeout(() => setOpacity(1), 100);
	}, []);

	const SettingsMenu = () => {
		return (
			<div className="bg-white rounded-lg p-1 shadow-1 ring-1 ring-gray-200 grid">
				<Menu
					className="grid grid-cols-1 pe-2"
					items={
						[
							{
								key: "4",
								icon: <SignOut size={16} weight="fill" />,
								onClick: logout,
								className: clsx(
									"hover:!bg-rose-50 hover:!text-rose-600  !h-8 !flex !items-center !text-[13px] !font-normal !px-4 !py-1.5 !rounded-md"
								),
								label: "Logout"
							},
							...(userRoleMenuItem && userRoleMenuItem.length > 0 ? [{ type: "divider" }] : []),
							...(userRoleMenuItem ?? [
								{ key: "5", type: "group", children: userRoleMenuItem, label: "Switch Role" }
							])
						] as MenuProps["items"]
					}
				/>
			</div>
		);
	};

	const onClick: MenuProps["onClick"] = (e) => {
		const item =
			_menu.find((item) => item.key === e.key) ??
			_menu.flatMap((item) => item.children ?? []).find((item) => item.key === e.key);

		if (!item || !item.href) return;
		if (isMobile && !isMinimized) toggleSidebar();
		navigate(item.href);
	};

	return (
		<>
			<Layout.Sider
				theme="light"
				width={isMinimized ? 60 : 240}
				data-isMobile={isMobile}
				className={clsx("smv-sidebar", isMinimized && "minimized")}>
				{!isMobile && (
					<div
						role="button"
						onClick={toggleSidebar}
						className={clsx(
							"absolute -right-3.5 top-1/2 -translate-y-1/2 z-50 inline-flex h-7 w-7 items-center justify-center gap-2 rounded-full bg-white p-1.5 ring-1 ring-gray-900/10 transition-transform",
							isMinimized && "rotate-180"
						)}>
						<CaretDoubleLeft size={16} weight="bold" className={"text-gray-500"} />
					</div>
				)}
				{!isMobile && (
					<div className="logo-container relative min-h-10">
						<img
							className={clsx(
								"absolute top-0 px-1 left-0 flex  h-full max-h-10 max-w-10 w-auto justify-center mix-blend-multiply",
								isMinimized ? "opacity-1" : "opacity-0"
							)}
							src={"./icons/common/nucleus-logo-short.svg"}
							height="40"
							width="40"
							alt="Nucleus"
						/>
						<img
							className={clsx(
								"absolute top-0 left-0  flex h-full max-h-10 w-auto justify-center mix-blend-multiply",
								isMinimized ? "opacity-0" : "animate-fade-in"
							)}
							src={"./icons/common/nucleus-logo-full.svg"}
							height="40"
							width="150"
							alt="Nucleus"
						/>
					</div>
				)}

				<div className="px-4">
					<Search isMinimized={isMinimized} />
				</div>

				<div className="w-full h-0.5 flex-col justify-center items-center inline-flex my-4">
					<div className="w-full h-px relative bg-slate-200" />
					<div className="w-full h-px relative bg-slate-50" />
				</div>

				<Menu
					style={{
						opacity,
						maxHeight: "calc(100vh - 324px)"
					}}
					onClick={onClick}
					className="scrollbar-hidden transition-opacity overflow-y-auto px-4"
					selectedKeys={selectedKeys}
					mode={isMinimized ? "vertical" : "inline"}
					defaultOpenKeys={user?.type === "DATA_ARCHITECT" ? ["/data-studio"] : undefined}
					items={_menu
						.filter((item) => (item.showForRole ? isUserInRoles(item.showForRole) : true))
						.map(({ selectedKeys, showForRole, ...rest }) => rest)}
				/>

				<div className="w-full h-0.5 flex-col justify-center items-center inline-flex my-4">
					<div className="w-full h-px relative bg-slate-200" />
					<div className="w-full h-px relative bg-slate-50" />
				</div>

				<div className="absolute bottom-0 left-0 right-0">
					<Menu
						style={{
							opacity,
							maxHeight: "calc(100vh - 200px)"
						}}
						onClick={onClick}
						className="scrollbar-hidden transition-opacity overflow-y-auto px-4"
						selectedKeys={selectedKeys}
						mode={isMinimized ? "vertical" : "inline"}
						items={[
							{
								key: "report-bug",
								onClick: () => sentry.showFeedBackModal(),
								icon: <Megaphone size={16} weight="bold" className="!text-gray-500" />,
								className: clsx(
									"!h-8 !flex !items-center !text-[13px] !font-normal !px-4 !py-1.5 !rounded-md"
								),
								label: (
									<span className="!text-gray-500 flex items-center justify-between">
										Report a Bug
										<KeyboardButton keys={["F8"]} />
									</span>
								)
							},

							{
								key: "changelog",
								onClick: handleOnViewChangelog,
								icon: (
									<div className="relative">
										<ClockCounterClockwise size={16} weight="bold" className="!text-gray-500" />
										{/* <span className="blinking-dot absolute -top-0.5 -left-0.5" /> */}
									</div>
								),
								className: clsx(
									"!h-8 !flex !items-center !text-[13px] !font-normal !px-4 !py-1.5 !rounded-md"
								),
								label: <span className="!text-gray-500 ">View Changelog </span>
							}
						]}
					/>

					<div className="w-full h-0.5 flex-col justify-center items-center inline-flex my-1">
						<div className="w-full h-px relative bg-slate-200" />
						<div className="w-full h-px relative bg-slate-50" />
					</div>

					<div
						className={clsx(
							"w-full h-[60px] py-2.5 rounded-lg justify-start items-center gap-2.5 grid",
							isMinimized ? "px-3" : "px-4 grid-cols-[auto,1fr]"
						)}>
						<Popover
							open={!isMinimized ? false : undefined}
							arrow
							overlayInnerStyle={{
								all: "unset"
							}}
							content={<SettingsMenu />}
							placement="rightBottom">
							<div
								className={clsx(
									"min-w-8 h-8 relative bg-[radial-gradient(100%_100%_at_50%_0%,_#008BE6_0%,_#93D4FF_100%)]",
									"rounded-lg shadow border border-black border-opacity-20 flex items-center justify-center",
									isMinimized && "cursor-pointer"
								)}>
								<div className="text-white text-xl font-extrabold font-['Inter'] leading-none">
									{initialOfName?.slice(0, 1)}
								</div>
							</div>
						</Popover>
						{!isMinimized && (
							<div className="cursor-pointer grid grid-cols-[1fr,auto] items-center gap-1.5 animate-fade-in">
								<div className="grow shrink basis-0 justify-center items-start gap-px grid grid-cols-1 overflow-hidden">
									<div className="self-stretch truncate text-gray-600 text-sm font-medium font-['Inter'] leading-tight">
										{user?.first_name} {user?.last_name}
									</div>
									<div className="self-stretch truncate text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]">
										{USER_ROLES_MAP[user?.type as TUserRoles]?.label}
									</div>
								</div>
								<Popover
									arrow
									overlayInnerStyle={{
										all: "unset"
									}}
									content={<SettingsMenu />}
									placement={isMobile ? "top" : "rightBottom"}>
									<div className=" px-2 py-1.5 hover:bg-gray-200 rounded-md" role="button">
										<Gear size={16} className="text-gray-500 cursor-pointer" />
									</div>
								</Popover>
							</div>
						)}
					</div>

					{isElectron() && (
						<div className="my-3">
							<NucleusUpdater />
						</div>
					)}
				</div>
			</Layout.Sider>
			{isMobile && (
				<div
					className={clsx(
						"transition-colors h-12 fixed top-0 w-full z-[999] px-3 flex items-center justify-between",
						!isMinimized ? "bg-slate-100" : "bg-white"
					)}>
					<img
						className={clsx("h-8")}
						src={"./icons/common/nucleus-logo-full.svg"}
						height="32"
						alt="Nucleus"
						onClick={() => navigate("/")}
					/>

					<button
						aria-expanded={!isMinimized}
						type="button"
						onClick={toggleSidebar}
						className="group relative h-6 w-6"
						style={
							{
								"--width": "1.25rem",
								"--thickness": "0.125rem",
								"--gap": "0.25rem",
								"--color": "#000",
								"--duration": "300ms"
							} as CSSProperties
						}>
						<span className="absolute left-1/2 top-1/2 h-[var(--thickness)] w-[var(--width)] -translate-x-1/2 translate-y-[calc(-150%-var(--gap))] transition-transform duration-[calc(var(--duration)*2/3)] before:absolute before:right-0 before:h-full before:w-full before:rounded-full before:bg-[var(--color)] before:transition-[width] before:delay-[calc(var(--duration)*1/3)] before:duration-[calc(var(--duration)*2/3)] group-aria-expanded:-translate-y-1/2 group-aria-expanded:-rotate-45 group-aria-expanded:delay-[calc(var(--duration)*1/3)] before:group-aria-expanded:w-[60%] before:group-aria-expanded:delay-0"></span>
						<span className="absolute left-1/2 top-1/2 h-[var(--thickness)] w-[var(--width)] -translate-x-1/2 -translate-y-1/2 rounded-full bg-[var(--color)] transition-transform duration-[calc(var(--duration)*2/3)] group-aria-expanded:rotate-45 group-aria-expanded:delay-[calc(var(--duration)*1/3)]"></span>
						<span className="absolute left-1/2 top-1/2 h-[var(--thickness)] w-[var(--width)] -translate-x-1/2 translate-y-[calc(50%+var(--gap))] transition-transform duration-[calc(var(--duration)*2/3)] before:absolute before:right-0 before:h-full before:w-[60%] before:rounded-full before:bg-[var(--color)] before:transition-[right] before:delay-[calc(var(--duration)*1/3)] before:duration-[calc(var(--duration)*2/3)] group-aria-expanded:-translate-y-1/2 group-aria-expanded:-rotate-45 group-aria-expanded:delay-[calc(var(--duration)*1/3)] before:group-aria-expanded:right-[40%] before:group-aria-expanded:delay-0"></span>
					</button>
				</div>
			)}
		</>
	);
}

export default Sidebar;
