import { apiInstance } from "api/instance";

type TPricingPayload = {
	visa_fee?: {
		amount: number;
		currency: string;
	};
	vfs_fee?: {
		amount: number;
		currency: string;
	};
	service_fee?: {
		amount: number;
		currency: string;
	};
	child_visa_fee?: {
		amount: number;
		currency: string;
	};
	comment: string;
	visaTypeId: string;
};

export const updateVisaTypeDefaultPricing = async (payload: TPricingPayload) =>
	(await apiInstance.put<ApiResponse<any>>(`data-studio/update-visa-type-default-pricing`, payload)).data.data;

export const updateVisaTypeGlobalPricing = async (payload: TPricingPayload) =>
	(await apiInstance.put<ApiResponse<any>>(`data-studio/update-visa-type-global-pricing`, payload)).data.data;

export const updateVisaTypePriceForOrg = async (payload: TPricingPayload & { orgId: string }) =>
	(await apiInstance.put<ApiResponse<any>>(`data-studio/update-visa-type-org-pricing`, payload)).data.data;

export const updateVisaTypePriceForSegment = async (payload: TPricingPayload & { segmentId: string }) =>
	(await apiInstance.put<ApiResponse<any>>(`data-studio/update-visa-type-segment-pricing`, payload)).data.data;

export const getOrgsList = async (body: {
	searchText?: string;
	counts?: boolean | number;
	sortBy: "asc" | "desc";
	pageNo?: number;
	pageSize?: number;
	status?: "APPROVED" | "PENDING" | "REJECTED" | "UNSTARTED";
	kamIds?: string[];
}) =>
	(
		await apiInstance.post<
			ApiResponse<{
				requests: {
					_id: string;
					name: string;
					status: "APPROVED" | "PENDING" | "REJECTED" | "UNSTARTED";
				}[];
			}>
		>("organisations/kyc-requests", body)
	).data.data;

export const getPriceByOrgIdAndVisaType = async (org_id: string, visa_type_id: string) =>
	(
		await apiInstance.get<
			ApiResponse<{
				visa_fee: {
					amount: number;
					currency: string;
				};
				vfs_fee: {
					amount: number;
					currency: string;
				};
				service_fee: {
					amount: number;
					currency: string;
				};
				child_visa_fee: {
					amount: number;
					currency: string;
				};
			}>
		>(`data-studio/price-by-org-id-and-visa-type?org_id=${org_id}&visa_type_id=${visa_type_id}`)
	).data.data;

export const bulkUpdateVisaTypePricing = async (payload: { file: File; comment: string }) => {
	const formData = new FormData();
	formData.append("pricing", payload.file);
	formData.append("comment", payload.comment);

	return (await apiInstance.put<ApiResponse<any>>(`data-studio/bulk-update-visa-type-pricing`, formData)).data.data;
};
