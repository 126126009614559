import { Warning } from "@phosphor-icons/react";
import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Form, InputNumber, Popconfirm, Select } from "antd";
import { createSchemaFieldRule } from "antd-zod";
import TextArea from "antd/es/input/TextArea";
import toast from "components/Commons/Toaster";
import { updateVisaTypeGlobalPricing } from "pages/Pricing/api";
import { usePricing } from "pages/Pricing/Context";
import { Flag } from "x-wings";
import { Flags } from "x-wings/Components/Flag/types";
import { z } from "zod";

const pricingSchema = z.object({
	countryId: z.string().min(1, "Country is required"),
	visaTypeId: z.string().min(1, "Visa Type is required"),
	visa_fee: z.object({
		amount: z.number().min(-1, "Amount must be non-negative"),
		currency: z.string()
	}),
	vfs_fee: z.object({
		amount: z.number().min(-1, "Amount must be non-negative"),
		currency: z.string()
	}),
	service_fee: z.object({
		amount: z.number().min(0, "Amount must be non-negative"),
		currency: z.string()
	}),
	child_visa_fee: z.object({
		amount: z.number().min(-1, "Amount must be non-negative"),
		currency: z.string()
	}),
	comment: z.string().min(1, "Comment is required")
});

const rule = createSchemaFieldRule(pricingSchema);

const GlobalPricing = () => {
	const { countriesVisaTypesQuery } = usePricing();

	const [form] = Form.useForm<z.infer<typeof pricingSchema>>();

	const globalPricingMutation = useMutation({
		mutationKey: ["updateVisaTypeGlobalPricing"],
		mutationFn: (data: z.infer<typeof pricingSchema>) => {
			return updateVisaTypeGlobalPricing(data);
		},
		onSuccess: () => {
			form.resetFields();
			toast.success("Pricing updated successfully!");
		}
	});

	const onFinish = (values: z.infer<typeof pricingSchema>) => {
		const parsedValues = pricingSchema.safeParse({
			...values,
			visa_fee: { ...values.visa_fee, currency: "INR" },
			vfs_fee: { ...values.vfs_fee, currency: "INR" },
			service_fee: { ...values.service_fee, currency: "INR" },
			child_visa_fee: { ...values.child_visa_fee, currency: "INR" }
		});
		if (!parsedValues.success) {
			toast.error(parsedValues.error.errors[0].message);
			return;
		}
		globalPricingMutation.mutate(parsedValues.data);
	};

	return (
		<Form form={form} onFinish={onFinish} layout="vertical" className="grid grid-cols-[1fr,auto,1fr] gap-5">
			{({ countryId }) => {
				const visaTypes =
					countriesVisaTypesQuery.data?.find((country) => country._id === countryId)?.visa_types ?? [];

				return (
					<>
						<div className="bg-amber-50 py-2 px-4 rounded-md border border-amber-200 col-span-3 flex items-center gap-2 w-full">
							<Warning className="text-amber-600" />
							<p className="text-sm text-amber-600">
								Warning: Changes made here will override all other pricing configurations (default,
								segment, and organization specific) and apply globally
							</p>
						</div>
						<section className="flex flex-col gap-4">
							<h2 className="uppercase text-primary-600 font-semibold tracking-wide text-xs">
								Visa Type Section
							</h2>
							<Form.Item label="Country" name="countryId" rules={[rule]}>
								<Select
									placeholder="Select a country"
									autoFocus
									virtual={false}
									showSearch
									loading={countriesVisaTypesQuery.isLoading}
									onChange={() => {
										form.setFieldsValue({ visaTypeId: undefined });
									}}
									optionFilterProp="data-country-name"
									filterOption={(input, option) =>
										option?.props["data-country-name"].toLowerCase().indexOf(input.toLowerCase()) >=
										0
									}>
									{countriesVisaTypesQuery.data?.map((country) => (
										<Select.Option
											key={country._id}
											value={country._id}
											data-country-name={country.name + country.symbol}>
											<div className="flex gap-2 items-center">
												<Flag code={country.flag_symbol as Flags} size="s" />
												<span>{country.name}</span>
											</div>
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label="Visa Type" name="visaTypeId" rules={[rule]}>
								<Select placeholder="Select a visa type" virtual={false} disabled={!countryId}>
									{visaTypes.map((visaType) => (
										<Select.Option key={visaType._id} value={visaType._id}>
											{visaType.visa_type}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</section>
						<Divider type="vertical" className="h-full" />

						<section className="grid grid-cols-2 gap-4">
							<h2 className="uppercase text-primary-600 font-semibold tracking-wide text-xs col-span-2">
								Pricing Section
							</h2>
							<Form.Item label="Visa Fee Amount" name={["visa_fee", "amount"]} rules={[rule]}>
								<InputNumber className="w-full" min={-1} />
							</Form.Item>
							<Form.Item label="VFS Fee Amount" name={["vfs_fee", "amount"]} rules={[rule]}>
								<InputNumber className="w-full" min={-1} />
							</Form.Item>
							<Form.Item label="Service Fee Amount" name={["service_fee", "amount"]} rules={[rule]}>
								<InputNumber className="w-full" min={0} />
							</Form.Item>
							<Form.Item label="Child Visa Fee Amount" name={["child_visa_fee", "amount"]} rules={[rule]}>
								<InputNumber className="w-full" min={-1} />
							</Form.Item>
							<Form.Item label="Comment" name="comment" rules={[rule]} className="col-span-2">
								<TextArea
									autoSize={{
										minRows: 2,
										maxRows: 3
									}}
								/>
							</Form.Item>
						</section>
						<div className="col-span-3">
							<Divider />
							<div className="justify-end items-center flex gap-3">
								<Button htmlType="reset">Reset</Button>
								<Popconfirm
									title="Override all pricing configurations globally?"
									onConfirm={form.submit}
									okText="Yes"
									cancelText="No"
									disabled={globalPricingMutation.isPending}>
									<Button type="primary" loading={globalPricingMutation.isPending}>
										Update Price
									</Button>
								</Popconfirm>
							</div>
						</div>
					</>
				);
			}}
		</Form>
	);
};

export default GlobalPricing;

