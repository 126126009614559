import { Spinner } from "@phosphor-icons/react/dist/ssr";
import { useQuery } from "@tanstack/react-query";
import { Divider, Form, Select } from "antd";
import { createSchemaFieldRule } from "antd-zod";
import { getCountriesVisaTypes } from "pages/DataStudio/VisaTypes/api";
import { getOrgsList, getPriceByOrgIdAndVisaType } from "pages/Pricing/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { clsx, Flag, Modal, useDebounceValue } from "x-wings";
import { Flags } from "x-wings/Components/Flag/types";
import { z } from "zod";

type TGetPricingModalProps = {
	children: ({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => React.ReactNode;
};

const pricingSchema = z.object({
	country_id: z.string({
		message: "Country is required"
	}),
	visa_type_id: z.string({
		message: "Visa Type is required"
	}),
	org_id: z.string({
		message: "Organization is required"
	})
});

const rule = createSchemaFieldRule(pricingSchema);

const GetPricingModal = ({ children }: TGetPricingModalProps) => {
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm<z.infer<typeof pricingSchema>>();
	const [pricing, setPricing] = useState<null | ExtractApiResponseFromPromise<
		ReturnType<typeof getPriceByOrgIdAndVisaType>
	>>(null);
	const [loading, setLoading] = useState(false);

	const [orgSearchQuery, setOrgSearchQuery] = useState("");
	const [debouncedOrgSearchQuery] = useDebounceValue(orgSearchQuery, 400);

	const countriesVisaTypesQuery = useQuery({
		queryKey: ["countries", "visa-types"],
		queryFn: getCountriesVisaTypes,
		initialData: [],
		select(data) {
			return data.filter((country) => country.is_supported).sort((a, b) => a.name.localeCompare(b.name));
		}
	});

	const orgsQuery = useQuery({
		queryKey: ["getOrgsList", debouncedOrgSearchQuery],
		queryFn: async () => {
			const data = await getOrgsList({
				searchText: debouncedOrgSearchQuery,
				sortBy: "asc",
				counts: false,
				pageNo: 1,
				pageSize: 10
			});
			return data.requests;
		},
		select: (orgs) => {
			return orgs.map((org) => ({
				label: org.name,
				value: org._id,
				status: org.status
			}));
		},
		initialData: [],
		enabled: !!debouncedOrgSearchQuery
	});

	const handleFinish = async (values: z.infer<typeof pricingSchema>) => {
		try {
			setLoading(true);
			const pricing = await getPriceByOrgIdAndVisaType(values.org_id, values.visa_type_id);
			setPricing(pricing);
		} finally {
			setLoading(false);
		}
	};

	const formValues = Form.useWatch([], form);

	useEffect(() => {
		if (!formValues) return;
		if (formValues.visa_type_id && formValues.org_id) {
			form.submit();
		}
	}, [form, formValues]);

	useEffect(() => {
		if (open) {
			form.resetFields();
		}
	}, [form, open]);

	return (
		<>
			{children({ open, setOpen })}

			<Modal title="Get Pricing for Org" open={open} onCancel={() => setOpen(false)} width={600} footer={null}>
				<div className="grid grid-cols-[1fr,auto,1fr] gap-2">
					<Form form={form} layout="vertical" className="grid gap-3" onFinish={handleFinish}>
						{({ country_id }) => {
							const visaTypes =
								countriesVisaTypesQuery.data.find((country) => country._id === country_id)
									?.visa_types || [];

							return (
								<>
									<Form.Item label="Organization" name="org_id" rules={[rule]}>
										<Select
											showSearch
											autoFocus
											placeholder="Search for an organization"
											onSearch={(val) => setOrgSearchQuery(val)}
											value={orgSearchQuery}
											filterOption={false}
											loading={orgsQuery.isLoading}
											virtual={false}
											notFoundContent={
												orgsQuery.isFetched && !orgsQuery.isLoading
													? "No organization found"
													: null
											}>
											{orgsQuery.data.map((org) => (
												<Select.Option key={org.value} value={org.value} data-name={org.label}>
													{org.label}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label="Country" name="country_id" rules={[rule]}>
										<Select
											placeholder="Select a country"
											virtual={false}
											showSearch
											loading={countriesVisaTypesQuery.isLoading}
											onChange={() => {
												form.setFieldsValue({ visa_type_id: undefined });
												setPricing(null);
											}}
											optionFilterProp="data-country-name"
											filterOption={(input, option) =>
												option?.props["data-country-name"]
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}>
											{countriesVisaTypesQuery.data?.map((country) => (
												<Select.Option
													key={country._id}
													value={country._id}
													data-country-name={country.name + country.symbol}>
													<div className="flex gap-2 items-center">
														<Flag code={country.flag_symbol as Flags} size="s" />
														<span>{country.name}</span>
													</div>
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item label="Visa Type" name="visa_type_id" rules={[rule]}>
										<Select placeholder="Select a visa type" virtual={false} disabled={!country_id}>
											{visaTypes.map((visaType) => (
												<Select.Option key={visaType._id} value={visaType._id}>
													{visaType.visa_type}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</>
							);
						}}
					</Form>
					<Divider className="h-full" type="vertical" />

					<div className={clsx("flex flex-col divide-y-[1px] space-y-2 divide-gray-200")}>
						<h2 className="text-gray-700 text-sm font-semibold flex items-center">
							Pricing for selected Org
							{loading && <Spinner size={15} className="inline ms-1 text-gray-500 animate-spin" />}
						</h2>
						{!!pricing ? (
							<>
								<label className="grid pt-2">
									<span className="text-gray-500 text-xs">Visa Fee</span>
									{pricing.visa_fee?.amount > 0 ? (
										<span className="font-semibold text-gray-600 text-sm">
											₹ {pricing?.visa_fee.amount}
										</span>
									) : (
										<span className="text-gray-400">--</span>
									)}
								</label>
								<label className="grid pt-2">
									<span className="text-gray-500 text-xs">VFS Fee</span>
									{pricing.vfs_fee?.amount > 0 ? (
										<span className="font-semibold text-gray-600 text-sm">
											₹ {pricing?.vfs_fee.amount}
										</span>
									) : (
										<span className="text-gray-400">--</span>
									)}
								</label>
								<label className="grid pt-2">
									<span className="text-gray-500 text-xs">Service Fee</span>
									{pricing.service_fee?.amount > 0 ? (
										<span className="font-semibold text-gray-600 text-sm">
											₹ {pricing?.service_fee.amount}
										</span>
									) : (
										<span className="text-gray-400">--</span>
									)}
								</label>
								<label className="grid pt-2">
									<span className="text-gray-500 text-xs">Child Visa Fee</span>
									{pricing.child_visa_fee?.amount > 0 ? (
										<span className="font-semibold text-gray-600 text-sm">
											₹ {pricing?.child_visa_fee.amount}
										</span>
									) : (
										<span className="text-gray-400">--</span>
									)}
								</label>
							</>
						) : (
							<p className="text-gray-400 h-full w-full grid place-items-center text-center text-xs italic">
								Select an organization & visa types to get pricing
							</p>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default GetPricingModal;
